import React, {useEffect} from 'react'
// import Carousel from '../components/Carusel'
import '../style/features.css'
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';

import caruselImg1 from '../img/the_Product-one.png'
import caruselImg2 from '../img/the_Product-two.png'
import caruselImg3 from '../img/the_Product-three.png'

import AOS from 'aos';
import 'aos/dist/aos.css'; 

export default function Features() {
    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])
    return (
        <section className="features" id="features">
            <div className="container">
                <div data-aos="fade-up" className="features-content">
                <div className="features-img">
                <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={caruselImg1}
                    alt="First slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={caruselImg2}
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={caruselImg3}
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                    </Carousel.Caption>
                </Carousel.Item>
                </Carousel>
                </div>
                    <div className="features-box">
                    <div className="features-wrapper">
                        <ul>
                            <li data-aos="zoom-in-up">Unique, innovative structure of “land” spaces</li>
                            <li data-aos="zoom-in-up">Ultra high quality models</li>
                            <li data-aos="zoom-in-up">Portals between metaverses</li>
                            <li data-aos="zoom-in-up">Multiple zoom levels</li>
                            <li data-aos="zoom-in-up">Meta games</li>
                        </ul>
                    </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}
