import React from 'react'
import '../style/main.css'
import gameVideo from '../video/Dubai_loop.mp4'
import gameVideo2 from '../video/Dubai_loop.webm'
import poster from '../img/main.png'




export default function Main() {
    return (
        <main id="home" className="main">
            <video autoPlay muted loop playsInline id="mainVideo" poster={poster}>
                <source src={gameVideo} type="video/mp4" />
                <source src={gameVideo2} type="video/webm" />
                <img src={poster} alt="poster" />
                
            </video>
            <div className="main-title">
                <div className="container">
                    <div className="title-items">
                        <div className="title-content">
                        <div className="title">
                            <h3>Welcome to the</h3>
                            <h1 className="glitch">
                                <span aria-hidden="true">Meta Metaverse</span>
                                Meta Metaverse
                                <span aria-hidden="true">Meta Metaverse</span>
                            </h1>
                        </div>
                        <div className="subtitle">
                            <p>The game where you create your own metaverse</p>
                        </div>
                        </div>  
                    </div>
                </div>
            </div>
        </main>
    )
}
