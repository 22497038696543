import React, {useEffect} from 'react'
import '../style/work.css'
import Carousel from 'react-bootstrap/Carousel'
import '../style/carusel.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import caruselImg1 from '../img/Play_to_earn.png'

import AOS from 'aos';
import 'aos/dist/aos.css'; 

export default function Work() {
    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])
    return (
        <section className="work" id="work">
            <div className="container">
                <div data-aos="fade-up" className="work-wrapper">
                    <div className="work-content">
                        <div className="work-content_wrapper">
                            <div className="work-content_title">
                                <h3>How does the game work?</h3>
                                <p>Games are merging with blockchains and virtual reality, allowing people to create powerful and profitable new avatars as well as new income streams and possibliities. In our game you build, develop, and resell your own metaverse.</p>
                                <p>The Meta Metaverse is the ultimate sim. It allows you to build a dynamic multi-verse which simulates the dynamics of cities, governments, even whole planets, including portals between metaverses with entirely different rules and logic.</p>
                            </div>
                        </div>
                    </div>
                    <div className="work-slider">
                        <div className="work-slider_wrapper">
                     
                            {/* <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={caruselImg1}
                                alt="First slide"
                                />
                                 <p>Game worlds are evolving and allowing dynamic user generated content and in-depth game play</p>
                                <Carousel.Caption>
                           
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={caruselImg1}
                                alt="Second slide"
                                />
                                <p>Play to Earn games market is about to explode in the next year 2022.</p>
                                <Carousel.Caption>
                                
                               
                                </Carousel.Caption>
                            </Carousel.Item> */}
                             
                                <img
                                className="d-block w-100"
                                src={caruselImg1}
                                alt="Third slide"
                                />
                                 <p>Creators and Corporations will want their own Metaverses with their own rules.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
