import React, { useState , useRef } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FormGroup() {

const [userEmail, setUserEmail] = useState('')


const SERVICE_ID = 'service_iajaj23'
const TEMPLATE_ID = 'template_5f5yrtp'
const USER_API = 'user_HCtna0oDqHDQABiks74DO'


const form = useRef();


  const sendEmail = (e) => {
    console.log(e);
    e.preventDefault();
    
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_API)
      .then((result) => {
          toast.success('Message has been successfully sent', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }, (error) => {
          console.log(error.text);
          toast.error(error.text, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      });
      form.current.reset()
  };

    return (
        
            <Form ref={form} onSubmit={sendEmail}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="name@example.com" onChange={e => setUserEmail(e.target.value)} name="email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} name="message" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Button variant="dark" type="submit">Send Message</Button>
                </Form.Group>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                    {/* Same as */}
                <ToastContainer />
            </Form>
    )
}
