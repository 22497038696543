import React, {useEffect} from 'react'
import '../style/athors.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; 




export default function Athors() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])

    return (
        <section className="athors" id="forwhom">
              <div className="forwhom-content">
                    <h2>For whom?</h2>
                </div>
            <div className="container">
                <div className="athors-list">
                    <div data-aos="flip-up" className="athors-item">
                        <div className="athors-item_wrapper">
                            <div className="athors-item_img-one"></div>
                            <h4>Artists</h4>
                        </div>
                    </div>
                    <div data-aos="flip-up" className="athors-item">
                        <div className="athors-item_wrapper">
                            <div className="athors-item_img-two"></div>
                            <h4>NFT-Collectors</h4>
                        </div>
                    </div>
                    <div data-aos="flip-up" className="athors-item">
                        <div className="athors-item_wrapper">
                            <div className="athors-item_img-three"></div>
                            <h4>Game Producers</h4>
                        </div>
                    </div>
                    <div data-aos="flip-up" className="athors-item">
                        <div className="athors-item_wrapper">
                            <div className="athors-item_img-four"></div>
                            <h4>Gamers</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
