import React from 'react'

import '../style/advisors.css'

export default function Advisors() {
    return (
        <section className="advisors">
        <div className="advisors-title">
            <h2>Our Advisors</h2>
        </div>
    <div className="container">
        <div className="advisors-list">
            <div data-aos="zoom-in" className="advisors-item">
                <div className="advisors-item_wrapper">
                    <div className="advisors-item_img-one"></div>
                    <h4>Alex Pentland</h4>
                    <p>Director of MIT Connection Science | Professor at MIT</p>
                    <a href="https://www.linkedin.com/in/alexsandypentland">LinkedIn</a>
                </div>
            </div>
            <div data-aos="zoom-in" className="advisors-item">
                <div className="advisors-item_wrapper">
                    <div className="advisors-item_img-two"></div>
                    <h4>Metagovernance Project</h4>
                    <p>Building standards and infrastructure for digital self-governance.</p>
                    <a href="https://metagov.org">Link</a>
                </div>
            </div>
            <div data-aos="zoom-in" className="advisors-item">
                <div className="advisors-item_wrapper">
                    <div className="advisors-item_img-three"></div>
                    <h4>Jeff Hood</h4>
                    <p>Co-founder of Block Squared Capital</p>
                    <a href="https://www.linkedin.com/in/jeffrhood">LinkedIn</a>
                </div>
            </div>

            <div data-aos="zoom-in" className="advisors-item">
                <div className="advisors-item_wrapper">
                    <div className="advisors-item_img-four"></div>
                    <h4>Eric Bonabeau</h4>
                    <p>Chief Scientific Officer at Telepathy Labs</p>
                    <a href="https://www.linkedin.com/in/eric-bonabeau-9a1a476">LinkedIn</a>
                </div>
            </div>

            <div data-aos="zoom-in" className="advisors-item">
                <div className="advisors-item_wrapper">
                    <div className="advisors-item_img-five"></div>
                    <h4>Ogar</h4>
                    <p>Crypto Artist / Metaverse Virtual Estate Architect</p>
                    <a href="https://twitter.com/Dat_ogar">Twitter</a>
                </div>
            </div>

            <div data-aos="zoom-in" className="advisors-item">
                <div className="advisors-item_wrapper">
                    <div className="advisors-item_img-six"></div>
                    <h4>Carl the Moon</h4>
                    <p>Co-founder of @Kasta_app/Influencer</p>
                    <a href="https://www.instagram.com/themooncarl/?hl=ru">Instagram</a>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}
