import React from 'react'
import '../style/product.css'

export default function Product() {
    return (
        <section className="product" id="product">
            <div className="container">
                <div className="product-content">
                    <h2>The product</h2>
                    <p>We allow people to easily create their own Metaverses. Metaverses can include high quality art, interactive game play, multiple zoom levels, physics tweaks, and more.</p>
                </div>
            </div>
        </section>
    )
}
