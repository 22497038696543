import React, {useState, useEffect} from 'react'
import '../style/navbar.css'
import { Link } from "react-scroll";


import navbarImg from '../img/metaverse_logo_small.png'



export default function Nav() {

    const [openModal, setOpenModal] = useState(true)
    const [header, setHeader] = useState("navbar")
    const openMo = () => setOpenModal(!openModal)
    const closeMenu = () => setOpenModal(true)


    const listenScrollEvent = () => {
        if (window.scrollY < 73) {
          return setHeader("navbar")
        } else if (window.scrollY > 70) {
          return setHeader("navbar2")
        } 
      }

      useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
      
        return () =>
          window.removeEventListener('scroll', listenScrollEvent);
      }, []);
  
    return (
        <nav className={header}>
            <div className="container">
               <div className="logo">
                   <a href="/"><img src={navbarImg} alt="img" /></a> 
               </div>
                <div className="navbar-list">
                    <ul>
                        <li className="navbar-item"><Link to="home"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                        >Home</Link></li>


                        <li className="navbar-item"><Link to="work"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                        >About</Link></li>


                        <li className="navbar-item"><Link to="product"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                        >Product</Link></li>


                        <li className="navbar-item"><Link to="forwhom"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                        >For whom</Link></li>


                        <li className="navbar-item"><Link to="economy"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                        >Economy</Link></li>

                        
                        <li className="navbar-item"><Link to="roadmap"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                        >Roadmap</Link></li>
                        
                    </ul>
                </div>
                
                <div className="burger-menu" onClick={openMo}>
                    <i className={openModal ? 'fas fa-bars' : 'fas fa-times'}></i>
                </div>

                <div className={openModal ? 'mobile-navbar-list' : 'mobile-navbar-list active'}>
                  <div className="burger-menu_logo">
                    <img src={navbarImg} alt="" />
                  </div>

                    <ul>
                    <li className="navbar-item"><Link to="home"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                         onClick={closeMenu}
                        >Home</Link></li>


                        <li className="navbar-item"><Link to="work"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                         onClick={closeMenu}
                        >About</Link></li>


                        <li className="navbar-item"><Link to="product"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                         onClick={closeMenu}
                        >Product</Link></li>


                        <li className="navbar-item"><Link to="forwhom"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                         onClick={closeMenu}
                        >For whom</Link></li>


                        <li className="navbar-item"><Link to="economy"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                         onClick={closeMenu}
                        >Economy</Link></li>

                        
                        <li className="navbar-item"><Link to="roadmap"
                         activeClass="active"
                         spy={true}
                         smooth={true}
                         offset={-100}
                         duration={500}
                         onClick={closeMenu}
                        >Roadmap</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
