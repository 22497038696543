import React, {useEffect} from 'react'
import Diagram from '../components/Diagram'
import '../style/economy.css'

import economyImg from '../img/token_economy.png'
import diagramImg from '../img/diagram.png'


import AOS from 'aos';
import 'aos/dist/aos.css'; 

export default function Economy() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])
    return (
        <section className="economy" id="economy">
                <div className="economy-title">
                    <h2>Token economy</h2>
                    <p>Tokenized via dual token economy</p>
                </div>
                <div className="container">
                <div data-aos="fade-up" className="economy-earn">
                    <div className="economy-earn_info">
                        <div className="economy-earn_img">
                            <img src={economyImg} alt="img" />
                        </div>
                        <div className="economy-earn_text">
                            <h4>FAD IS CORE OF P2EARN</h4>
                            <p><span className="circle1">70%</span> - Play2Earn</p>
                            <p><span className="circle2">30%</span> - staking and LP</p>
                        </div>
                    </div>
                </div>
                <div className="economy-meta">
                        <div className="economy-meta_title">
                            <h3>Token Economy(vox)</h3>
                        </div>
                        <div className="economy-meta_list">
                            <ul>
                                <li data-aos="zoom-in-up">VOX token allows all stakeholders to govern the Meta Metaverse</li>
                                <li data-aos="zoom-in-up">Can be used to purchase services in metaverse.</li>
                                <li data-aos="zoom-in-up">Staking rewards to be available.</li>
                                <li data-aos="zoom-in-up">Ecosystem fund for artists and grants to 3rd parties to grow Meta Metaverse.</li>
                            </ul>
                        </div>
                </div>
                <div className="economy-diagram">
                    {/* <Diagram /> */}
                    <img src={diagramImg} alt="diagram" />
                </div>
            </div>
        </section>
    )
}
