import React from 'react'
import '../style/footer.css'
import FormGroup from './form'

import twitterIcon from '../img/twitter.png'
import telegramIcon from '../img/telegram.png'
import discordIcon from '../img/discord.png'
import mediumIcon from '../img/medium.png'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
            <div className="join-us">
            <div className="container">
                <div className="join-us_title">
                    <h2>Join our journey to the future</h2>
                </div>
            </div>
            </div>
                <div className="wrapper">
                    <div className="info">
                        <div className="info-wrapper">
                            <div className="info-logo" data-aos="zoom-in" data-aos-duration="6000">
                            <h3 className="glitch">
                            <span aria-hidden="true">Meta Metaverse</span>
                            Meta Metaverse
                            <span aria-hidden="true">Meta Metaverse</span>
                            </h3>
                            </div>
                            <div className="info-contant">
                                    <div className="social">
                                        <a href="https://twitter.com/m2verse"><img src={twitterIcon} alt="twitter" /></a>
                                        <a href="https://t.me/metametaversenews"><img src={telegramIcon} alt="telegram" /></a>
                                        <a href="https://discord.gg/dBc9pWAGAk"><img src={discordIcon} alt="discord" /></a>
                                        <a href="https://medium.com/@metametaverse"><img src={mediumIcon} alt="medium" /></a>
                                    </div>
                                  
                              
                            </div>
                        </div>
                    </div>
                    {/* <div className="news">
                        <div className="news-wrapper">
                            <h3>News</h3>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptatem rerum impedit nobis cumque ad quos, aspernatur vitae velit, soluta, beatae veniam omnis eaque nulla odio. Non eos enim explicabo aliquid.</p>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate incidunt harum optio. Sed voluptates quis ut eius corporis ad, molestiae voluptas consequatur expedita quo nesciunt. Possimus tenetur delectus aliquid deserunt.</p>
                        </div>
                    </div> */}
                    <div className="contact-form">
                        <div className="contact-form_wrapper">
                            <FormGroup />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
