import React from 'react'
import '../style/team.css'



export default function Team() {
    return (
        <section className="team">
            <div className="team-title">
                <h2>Founding team</h2>
            </div>
            <div className="container">
                <div className="team-list">
                    <div data-aos="zoom-in" className="team-item">
                        <div className="team-item_wrapper">
                            <div className="team-item_img-one"></div>
                            <h4>Joel Dietz</h4>
                            <p>Founding Member of Ethereum /Started MetaMask</p>
                            <a href="https://www.linkedin.com/in/joeldietz">LinkedIn</a>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="team-item">
                        <div className="team-item_wrapper">
                            <div className="team-item_img-two"></div>
                            <h4>Lyubomyr Pavlyk</h4>
                            <p>IT/Blockchain Entrepreneur, ITEXPORTUNION.COM co-founder</p>
                            <a href="https://www.linkedin.com/in/lyubomyr-pavlyk-111a4b48/">LinkedIn</a>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className="team-item">
                        <div className="team-item_wrapper">
                            <div className="team-item_img-three"></div>
                            <h4>Oleh Mykhaylovych</h4>
                            <p>SERIAL gaming ENTREPRENEUR, Dapplica, Immortal games co-founder</p>
                            <a href="https://www.linkedin.com/in/olehmykhaylovych">LinkedIn</a>
                        </div>
                    </div>

                    <div data-aos="zoom-in" className="team-item">
                        <div className="team-item_wrapper">
                            <div className="team-item_img-four"></div>
                            <h4>Ivan Kravets</h4>
                            <p>CTO, Dapplica, Immortal games co-founder</p>
                            <a href="https://www.linkedin.com/in/ivan-kravets-8b5244">LinkedIn</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
