import React, {useEffect} from 'react'
import '../style/roadmap.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; 




export default function Roadmap() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        })
    }, [])
    return (
        <section className="roadmap" id="roadmap">
                <div className="roadmap-title">
                    <h2>The Roadmap</h2>
                </div>
                <div className="container">
                <div className="roadmap-map">
                    <div className="roadmap-items">
                        <div data-aos="zoom-in" className="roadmap-item">
                            <div className="roadmap-wrapper">
                                <div className="roadmap-block-title">
                                    <h3>2021 Q4</h3>
                                </div>
                                <div className="poadmap-content">
                                   <ul>
                                       <li>Metaverse language spec (noumenx) </li>
                                       <li>Reference client in Unreal Engine</li>
                                   </ul>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="roadmap-item">
                            <div className="roadmap-wrapper">
                                <div className="roadmap-block-title">
                                    <h3>2022 Q1</h3>
                                </div>
                                <div className="poadmap-content">
                                    <ul>
                                        <li>Exploration and Coin collection game</li>
                                        <li>Initial parcel land sale</li>
                                        <li>Other client development start</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className="roadmap-item">
                            <div className="roadmap-wrapper">
                                <div className="roadmap-block-title">
                                    <h3>2022 Q2</h3>
                                </div>
                                <div className="poadmap-content">
                                    <ul>
                                        <li>Game development client</li>
                                        <li>Avatars and Quests</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div data-aos="zoom-in" className="roadmap-item">
                            <div className="roadmap-wrapper">
                                <div className="roadmap-block-title">
                                    <h3>2022 Q3</h3>
                                </div>
                                <div className="poadmap-content">
                                <ul>
                                    <li>Play to learn integration</li>
                                    <li>Hardware integrations</li>
                                </ul>
                                </div>
                            </div>
                        </div>

                        <div data-aos="zoom-in" className="roadmap-item">
                            <div className="roadmap-wrapper">
                                <div className="roadmap-block-title">
                                    <h3>2022 Q4</h3>
                                </div>
                                <div className="poadmap-content">
                                    <ul>
                                        <li>Meta game</li>
                                    </ul>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
