import './App.css';
import Header from './components/Header'
import Main from './components/Main'
import Product from './pages/Product'
import Features from './pages/Features'
import Work from './pages/Work'
import Roadmap from './pages/Roadmap';
import Footer from './components/Footer.jsx';
import Athors from './pages/Athors';
import Economy from './pages/Economy';
import Team from './pages/Team';
import Advisors from './pages/Advisors';

function App() {

 

  return (
    <div className='App'>
        <Header  />
        <Main />
          <Work />
          <Product />
          <Features />
          <Athors />
          <Economy />
          <Team />
          <Advisors />
        <Roadmap />
        <Footer />
    </div>
  );
}

export default App;
